<template>
  <div class="sign-up-container">
    <input class="hide" type="email">
    <input class="hide" type="password">
    <mdb-row class="sign-up-frame">
      <mdb-col col="6" class="sign-up-left">
        <div class="left-container">
          <div>
            <img src="@/assets/images/img_theme.png" alt="">
            <h2>지금바로 회원가입 후<br/>
              다양한 리소스를 받아보세요!</h2>
          </div>
        </div>
      </mdb-col>
      <mdb-col col="6" class="sign-up-right">
        <div class="right-container">
          <div class="sign-up-box">
            <h1>회원가입</h1>
            <div class="input-box">
              <div class="email box">
                <p>이메일</p>
                <input type="email" placeholder="이메일 입력" class="custom-input-box"
                       :class="{warning: !isEmail_1 || !isEmail_2}" v-model="email" @blur="validateInput('email')">
                <span class="warning-text" v-if="!isEmail_1">이메일이 유효하지않습니다.</span>
                <span class="warning-text" v-if="!isEmail_2">이미 가입된 이메일입니다.</span>
              </div>
              <div class="box">
                <p>비밀번호</p>
                <input type="password" placeholder="8자리 이상 ~ 12자리 이내" class="custom-input-box"
                       :class="{warning:!isPassword}" v-model="password" @blur="validateInput('password')">
                <span class="warning-text" v-if="!isPassword">8자리 이상 12자리 이내로 설정해주세요.(특수문자 제외)</span>
                <input type="password" placeholder="비밀번호 확인" class="check-pw custom-input-box"
                       :class="{warning:!isCheckPassword}" v-model="checkPassword" @blur="validateInput('checkPassword')">
                <span class="warning-text" v-if="!isCheckPassword">비밀번호가 맞지 않습니다.</span>
              </div>
              <div class="box">
                <p>직업</p>
                <div class="select-box">
                  <img class="select-icon" src="@/assets/images/icon/icon_selector.png">

                  <mdb-select class="custom-input select-input" id="select-job"
                              @getValue="selectValue($event)"
                              placeholder="직업 선택" size="md" v-model="selectOptions"/>
                </div>
              </div>
              <div class="box">
                <p>관심분야</p>
                <input type="text" placeholder="태그 10개까지 입력 가능 (예: #AI #웹 프론트)" class="custom-input-box" v-model="tag"
                       @input="tagFormat">
              </div>
            </div>
            <div class="check-box">
              <div class="all-check">
                <input class="form-check-input" type="checkbox" id="checkbox0" @change="checkAll('all',isAllClick)" v-model="isAllClick">
                <label for="checkbox0">모든 약관에 동의합니다.</label>
              </div>
              <div class="check">
                <input class="form-check-input" type="checkbox" id="checkbox1" @change="checkAll" v-model="consent_1">
                <label for="checkbox1">만 14세 이상입니다.</label>
              </div>
              <div class="check">
                <input class="form-check-input" type="checkbox" id="checkbox2" @change="checkAll" v-model="consent_2">
                <label for="checkbox2">서비스 <span @click="showTerms('service')">이용약관</span>에 동의합니다.</label>

              </div>
              <div class="check">
                <input class="form-check-input" type="checkbox" id="checkbox3" @change="checkAll" v-model="consent_3">
                <label for="checkbox3"><span @click="showTerms('private')">개인정보수집방침</span> 수집 및 이용에 동의합니다.</label>
              </div>
            </div>
            <button class="sign-up-btn custom-btn" @click="signUp">완료</button>
          </div>
        </div>
      </mdb-col>
    </mdb-row>
    <Terms v-if="isTerms" :category="termsCategory" @closeTerms="closeTerms"/>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {isValidEmail, isValidPassword} from "@/lib/validation";
import {auth, firestore, Timestamp} from "@/firebase/firebaseConfig";
import {mdbRow, mdbCol, mdbSelect} from "mdbvue";
import Progress from "@/components/Progress";
import Terms from "@/components/Terms.vue";
import {firebaseError} from "@/firebase/firebaseError";
// import axios from "axios";

export default {
  name: "SignUp",
  components: {
    mdbRow, mdbCol, mdbSelect,
    Progress, Terms
  },
  data() {
    return {
      termsCategory: '',
      fbCollection: 'user',
      email: '',
      password: '',
      checkPassword: '',
      job: '',
      tag: '',
      isEmail_1: true,
      isEmail_2: true,
      isPassword: true,
      isCheckPassword: true,
      isProgress: false,
      isTerms: false,
      selectOptions: [
        {text: '학생', value: '학생'},
        {text: '디자이너', value: '디자이너'},
        {text: 'S/W 개발자', value: 'S/W 개발자'},
        {text: 'H/W,F/W 개발자', value: 'H/W,F/W 개발자'},
        {text: 'AI 개발', value: 'AI 개발자'},
        {text: '기타', value: '기타'},
      ],
      isAllClick: false,
      consent_1: false,
      consent_2: false,
      consent_3: false,
      lastLog: '',
    }
  },
  methods: {
    checkAll(index, value) {
      const self = this;
      if (index === 'all') {
        self.consent_1 = value;
        self.consent_2 = value;
        self.consent_3 = value;
      }
      if (self.consent_1 && self.consent_2 && self.consent_3)
        self.isAllClick = true
      else self.isAllClick = false
    },

    validateInput(value) {
      const self = this;

      if (value === 'email') {
        if (self.email.trim().length !== 0)
          self.isEmail_1 = isValidEmail(self.email);
        else self.isEmail_1 = true
        self.checkDouble()
      } else {
        if (self.password.trim().length !== 0)
          self.isPassword = isValidPassword(self.password);
        else self.isPassword = true

        if (self.checkPassword.trim().length !== 0) {
          if (self.password !== self.checkPassword) self.isCheckPassword = false
          else self.isCheckPassword = true;
        } else self.isCheckPassword = true
      }
    },
    checkValidateInput() {
      const self = this;

      if (self.email.trim().length === 0 || !self.isEmail_1 || !self.isEmail_2) return '가입 정보를 확인해주세요.';
      if (self.password.trim().length === 0 || !self.isPassword) return '가입 정보를 확인해주세요.';
      if (self.checkPassword.trim().length === 0 || !self.isCheckPassword) return '가입 정보를 확인해주세요.';
      if (!self.isEmail_1 || !self.isEmail_2 || !self.isPassword || !self.checkPassword) return '가입 정보를 확인해주세요.'
      if (!self.isAllClick) return '약관에 동의해주세요';
      return true;
    },
    checkDouble() {
      const self = this;

      firestore.collection('user')
          .where('id', '==', self.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              self.isEmail_2 = true
            } else {
              self.email = '';
              self.isEmail_2 = false
            }
          });
    },
    signUp() {
      const self = this;
      self.isProgress = true;

      const id = self.email;
      const password = self.password;

      const errorMessage = self.checkValidateInput();
      if (errorMessage !== true) {
        self.$swal.fire(errorMessage);
        self.isProgress = false;
        return;
      }

      auth.createUserWithEmailAndPassword(id, password).then(async (userCredential) => {
        let user = userCredential.user;

        const _userInfo = {
          auth: {email: id},
          tag: self.tag,
          job: self.job,
          regDate: Timestamp.fromDate(new Date()),
          currentLog: Timestamp.fromDate(new Date())
        };
        await user.updateProfile({displayName: this.email.split('@')[0]})
        await auth.signOut();
        const uid = user.uid;
        await firestore.collection(self.fbCollection).doc(uid).set(_userInfo)
            .then(async () => {
              self.isProgress = false;
              self.$swal.fire({title: '등록 완료', text: '회원 등록이 완료되었습니다.', icon: 'success'})
              await self.$router.push({name: 'Login'})
            })
            .catch((err) => {
              // 실패 후 실행할 코드
              self.isProgress = false;
              firebaseError(err);
            })
      })
    },
    // async signUp() {
    //   const self = this;
    //   self.isProgress = true;
    //
    //   const errorMessage = self.checkValidateInput();
    //   if (errorMessage !== true) {
    //     self.$swal.fire(errorMessage);
    //     self.isProgress = false;
    //     return;
    //   }
    //
    //   const _data = JSON.stringify({
    //     "id": self.email,
    //     "password": self.password,
    //     "job": self.job,
    //     "tag": self.tag,
    //   });
    //
    //   const config = {
    //     method: 'post',
    //     url: `${self.$store.state.serverUrl}/createUserAccount`,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     data: _data
    //   };
    //   axios(config)
    //       .then(res => {
    //         self.isProgress = false;
    //         if (res.data.result === 'success') {
    //           self.$swal.fire({title: '등록 완료', text: '회원 등록이 완료되었습니다.', icon: 'success'})
    //           self.$router.push({name: 'Login'})
    //         } else {
    //           console.log(res.data.message)
    //           self.$swal.fire({title: '등록 실패', text: '회원 등록을 실패하였습니다. 다시 시도해 주세요.', icon: 'error'})
    //         }
    //       })
    // },
    selectValue(value) {
      const self = this;
      self.job = value
    },
    showTerms(index) {
      const self = this
      self.termsCategory = index
      self.isTerms = true
    },
    closeTerms() {
      const self = this;
      self.termsCategory = ''
      self.isTerms = false
    },
    tagFormat() {
      let input = this.tag

      input = input.replace(/,\s/g, ' #'); // 쉼표 뒤에 띄어쓰기가 있는 경우 #로 바꿈
      if (input !== '' && input[0] !== '#') { // 첫 글자가 #가 아닌 경우
        input = '#' + input; // 문자열의 앞에 # 추가
      }
      const tagCount = (input.match(/#/g) || []).length;
      if (tagCount > 10) {
        input = input.substring(0, input.lastIndexOf('#', 139)); // 마지막 #로부터 최대 10개까지만 허용
      }
      this.tag = input; // 변경된 문자열을 입력값으로 설정
    }
  },
}
</script>

<style scoped>

.sign-up-container {
  font-weight: 500;
  background: #FFFFFF; /* 배경색 변경 */
  /*height: 892px;*/
  width: 100%;
}

.hide {
  position: absolute;
  z-index: -10;
}

.sign-up-frame {
  margin: 0 auto;
  background: #ffffff;
  width: 100%;
  height: 100%;
}

.sign-up-frame .sign-up-left,
.sign-up-frame .sign-up-right {
  margin: 0;
  padding: 0;
}

.sign-up-frame .sign-up-left {
  background: #FBF4EB;
  display: flex;
  justify-content: flex-end;
}

.sign-up-left .left-container,
.sign-up-right .right-container {
  width: 612px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-left .left-container img {
  width: 482px;
  height: 350px;
}

.sign-up-left h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.right-container .sign-up-box {
  margin: 80px 0;
  width: 343px;
}

.right-container .sign-up-box h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 60px;
}

.right-container .sign-up-box .input-box {
  display: flex;
  flex-direction: column;
}

.sign-up-box .input-box .box {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 10px;
}

.sign-up-box .input-box .box .warning-text {
  color: #EF4444;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-top: 6px;
  padding: 0 16px;
}

.sign-up-box .input-box .box.email {
  margin: 0;
}

.sign-up-box .input-box .box p {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.sign-up-box .input-box .box .warning {
  border: 1px solid #EF4444;
}

.sign-up-box .input-box .check-pw {
  margin-top: 10px;
}

.sign-up-box .input-box input::placeholder {
  color: #C7C6CD;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}

.sign-up-box .input-box input:focus {
  outline: 1px solid #FF8A00;
}

.sign-up-box .check-box {
  margin: 30px 0;
  text-align: start;
}

.form-check-input[type="checkbox"] + label {
  margin: 0;
  padding-left: 24px;
  height: 18px;
  transform: translateY(-4px);
}

.form-check-input[type="checkbox"] + label:before {
  margin: 1px 0 0 0 !important;
  border: 2px solid #E4E4E7;
}

.form-check-input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #ff8a00;
  border-bottom: 2px solid #ff8a00;
  border-top: 0;
  border-left: 0;
}

.sign-up-box .check-box .all-check,
.sign-up-box .check-box .check {
  margin-top: 10px;
  height: 20px;
}

.sign-up-box .check-box label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #71717A;
}

.sign-up-box .check-box label span {
  font-weight: 700;
  text-decoration-line: underline;
}

.sign-up-box .check-box .all-check label {
  font-weight: 700;
  color: #000000;
}

/* select */
.select-box {
  position: relative;
  margin-bottom: 10px;
}


.select-box .select-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.select-input::v-deep(.caret) {
  display: none;
}

.select-input::v-deep(.select-wrapper.md-form),
.select-input::v-deep(.md-form.select-dropdown) {
  margin: 0 !important;
}

.select-input::v-deep(.select-active input) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.select-input::v-deep(.mdb-select-789397) {

}

.select-input::v-deep(.collapse-item[data-v-39a3e486]) {
  top: 100%;
}


.select-input::v-deep(.dropdown-content.select-dropdown) {
  border-radius: 20px;
}

.select-input::v-deep(.select-inner-wrapper) {
  height: 100%;
}

.select-input::v-deep(.options-container[data-v-6c2c7caf]) {
  overflow-y: auto;
}

.select-input::v-deep(.select-inner-wrapper li) {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #E4E4E7 solid;
}

.select-input::v-deep(.form-control)::placeholder {
  color: #C7C6CD;
}

.select-input::v-deep(.select-inner-wrapper li.active) {
  background: #fafafa;
}

.select-input::v-deep(.select-inner-wrapper li.active span) {
  color: #ff8a00;
}


.select-input::v-deep(.select-inner-wrapper li span) {
  font-size: 16px;
  color: #000000;
}

.custom-input::v-deep(.md-form .form-control) {
  font-weight: 500;
  margin-bottom: 0 !important;
  padding: 0;
}

.custom-input::v-deep(.md-form input:not(.browser-default)) {
  height: 52px;
  width: 100%;
  padding: 0 16px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px #E4E4E7 solid;
}

.custom-input::v-deep(.md-form input:not(.browser-default):focus) {
  border: 1px #ff8a00 solid;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.sign-up-box .sign-up-btn {
  background: #1C2027;
  width: 100%;
}

</style>